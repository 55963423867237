import { FormatColorFill, ReportProblemOutlined } from "@material-ui/icons";
import Button from "components/Button";
import { useCsvToGridDataAsync } from "components/DataGrid/hooks";
import { IRow } from "csv/types";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import { useProject } from "pages/Workspace/hooks";
import { CsvFile } from "pages/Workspace/types";
import React, { useCallback } from "react";
import type { ButtonProps } from "react-bootstrap";
import { useStore } from "store";
import styled from "styled-components";
import { Toast } from "utils";

type Props = ButtonProps & {
  file?: CsvFile;
};

const UnsavedText = styled.p`
  text-transform: uppercase;
  margin-bottom: 0;
  span {
    font-weight: 600;
    font-size: 0.75rem;
    color: #f2994a;
  }

  svg {
    margin-right: 0.625rem;
    fill: #f2994a;
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const AutofillRMPAButton: React.FC<Props> = ({
  file,
  variant = "clt-files",
  size = "thin",
  ...props
}) => {
  const project = useProject();
  const store = useStore();
  const currentUser = store.auth.current;

  const [columns, rows, csvLoaded, setRows] = useCsvToGridDataAsync({
    sheet: file?.sheet,
    editable: file?.editable ?? true,
    schema: file?.schema,
  });

  const hasEditPermission =
    currentUser &&
    (currentUser.isAdmin ||
      currentUser.id === project?.creator ||
      project?.adminUsers.includes(currentUser.id) ||
      project?.rmPriceUsers.includes(currentUser.id));

  const update = useCallback(
    (rows: IRow[]) => {
      if (!file) return;

      const { updatable, sheet } = file;
      if (updatable) {
        const rowCopy = rows.map(({ id, ...rest }) => rest) as IRow[];

        // Include hidden columns, but not the "id".
        const headers = columns
          .filter(({ key }) => key !== "id")
          .map(({ key }) => key);

        sheet.setCsvString(rowCopy, headers);
      }
    },
    [columns, file]
  );

  const getRMPAFilters = useCallback((rows: IRow[]) => {
    const filters = rows
      .filter(
        (row) =>
          row["is_locked"]?.toString().toLowerCase() !== "true" &&
          row["pack_type"] !== "" &&
          row["plant"] !== ""
      )
      .map((row) => ({
        r_code: row["rcode"] as string,
        product_name: row["raw_material_desc"] as string,
        plant: row["plant"] as string,
        bulk_or_drum: row["pack_type"] as string,
      }));
    return filters;
  }, []);

  const handleAutofillRMPAClick = useCallback(async () => {
    if (!project || !file) return;
    if (!project.costingDate) {
      Toast.danger("Please set the costing date.");
      return;
    }

    project.setLoading();

    try {
      // Fetch data
      const filters = getRMPAFilters(rows);
      if (filters.length <= 0) {
        Toast.danger("None of the rows can be populated.");
        return;
      }

      const { results } = await store.rmPriceAssumptions.list({
        costing_date: project.costingDate.substring(0, 10),
        rmpa_data: filters,
      });

      if (!results.length) {
        Toast.danger("No data were found in the database.");
        return;
      }

      // Update grid data
      const newRows = cloneDeep(rows).map((row) => {
        // Might be slow if we are searching many rows
        const newData = store.rmPriceAssumptions.findRMPriceAssumption(
          row["rcode"] as string,
          row["raw_material_desc"] as string,
          row["plant"] as string
        );

        if (newData === null || newData === undefined) return row;

        row["supplier"] = newData.vendorName;
        row["supply_location"] = newData.supplyLocationCountry;
        row["inco_term"] = newData.incoTerm;
        row["currency"] = newData.currencyCode;
        row["rm_purchase_price"] = newData.rmPurchasePrice;
        row["supplier_freight"] = newData.supplyFreight;
        row["drumming_diff/mt"] = newData.drummingDifferential;
        row["unit_of_measure"] = newData.unitOfMeasure;

        return row;
      }) as IRow[];

      setRows(newRows);
      update(newRows);

      // Immediately save input sheets to display changes in frontend
      await store.defineSheets.saveAll(project.id);
      await store.defineSheets.list(project.id);

      Toast.success("RM Price Assumptions table filled.");
    } catch (e) {
      Toast.danger("Unable to fill RM Price Assumptions table.");
      return;
    } finally {
      project.setNotLoading();
    }
  }, [
    file,
    getRMPAFilters,
    project,
    rows,
    setRows,
    store.defineSheets,
    store.rmPriceAssumptions,
    update,
  ]);

  return (
    <>
      <Button
        variant={variant}
        size={size}
        onClick={handleAutofillRMPAClick}
        disabled={!hasEditPermission || project?.loading || project?.isDirty}
        {...props}
      >
        <FormatColorFill />{" "}
        {project?.isDirty ? "Please save changes first" : "Autofill RMPA"}
      </Button>
      <UnsavedText>
        <ReportProblemOutlined />
        <span>Reminder: Autofill requires Bulk/Drum to be populated.</span>
      </UnsavedText>
    </>
  );
};

export default observer(AutofillRMPAButton);
